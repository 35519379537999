body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Helvetica Inserat';
  src: local('Helvetica Inserat'), url(./fonts/HelveticaInseratLTStd-Roman.otf) format('opentype');
}

@font-face {
  font-family: "Swiss911 XCm BT";
  src: local('Swiss911 XCm BT'), url(./fonts/swiss-911-extra-compr9c22f.otf) format('opentype');
}

@font-face {
  font-family: 'swiss911_xcm_btregular';
  src: url(./fonts/swiss-911-extra-compr9c22f-webfont.woff2) format('woff2'),
  url(./fonts/swiss-911-extra-compr9c22f-webfont.woff) format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Korinna";
  src: local('Korinna'), url(./fonts/OPTIKorinna-Agency.otf) format('opentype');
}

@font-face {
  font-family: "Gyparody";
  src: local('Gyparody'), url(./fonts/gyparody-hv.ttf) format("truetype");
}

@font-face {
  font-family: "Chilanka";
  src: local('Chilanka'), url(./fonts/Chilanka-Regular.ttf) format("truetype");
}

